<template>
  <div class="richmond richmondpc">
    <m-header-richmond title="Night Market Coupon"></m-header-richmond>
    <div class="en-m-header">
      <img @click="handleBack" src="@/assets/icon_back@3x.png" alt="back">
      <p>Night Market Coupon</p>
    </div>
    <div class="main" ref="main"  @scroll="handleScroll($event)">
      <div class="main-head">
        <img src="@/assets/richmond/RNM_2022-logo.png" alt="" />
      </div>
      <!-- <div class="update" v-if="searchVal != ''" @click="update()"><img src="@/assets/goods/icon_back_2@2x.png" alt=""></div> -->
      <div class="searchVal">
        <img src="@/assets/richmond/search.png" alt="" />
        <input type="text" v-model="searchVal" ref="activeElement" @keyup.enter="searchEnter" @change="search()" placeholder="Search Booth # or Booth Name" />
      </div>
      <div>
        <van-swipe :autoplay="3000" :stop-propagation="false">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <div @click.stop="handleHref(image.href)">
              <img :src="image.src" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="coupon" v-if="couponList.length">
        <li  v-for="(item, index) in couponList" :key="index" :class="item.level != 1 ?'item2':'item'">
            <img v-show="item.level != 1" class="hot" src="@/assets/richmond/Group964.png" alt="">
          <div class="item-pic">
            <img :class="item.status==1?'item-pic-grey':''" :src="item.coupon_info.pic_link?item.coupon_info.pic_link.url:''" alt="" />
          </div>
          <div class="item-content">
            <div class="item-market">
              <div><img src="@/assets/richmond/map.png" alt="" /></div>
              <div class="addressMap">{{item.bus_info.toString()}}</div>
            </div>
            <!-- #979797 -->
            <div v-if="item.status == 0 && item.level != 1" class="use-btn" @click="useNow(item.b_id,item.status)"> <img src="@/assets/richmond/hotpng.png" alt=""> USE NOW</div>
            <div v-else-if="item.status == 0 && item.level == 1" class="use-btn3" @click="useNow(item.b_id,item.status)"> USE NOW</div>
            <div v-else-if="item.status == 1 && item.level != 1" class="use-btn2" @click="useNow(item.b_id,item.status)"><img src="@/assets/richmond/hotpng.png" alt="">USED</div>
            <div v-else-if="item.status == 1 && item.level == 1" class="use-btn2" @click="useNow(item.b_id,item.status)">USED</div>
          </div>
        </li>
      </div>
      <div v-else class="coupon">
          <div class="nomore">
              {{nomoreVal}}
          </div>
      </div>
    </div>
    <div class="flex-btn">
      <div class="flex-item">
        <div @click="shareClick">Send To Friends</div>
      </div>
    </div>
    <div class="fixed" v-show="fixedShow && step2">
      <div class="fixed_right"></div>
      <div class="fixed_search2">
        <img
          @click="step2Click()
          "
          src="../../../assets/market/btn4.png"
          alt=""
        />
      </div>
    </div>
    <div class="fixed" v-show="fixedShow && step3">
      <div class="fixed_top fixed_top_STEP3"></div>
      <div class="fixed_search3 fixed_top_STEP3">
        <img
          @click="step3Click()"
          src="../../../assets/market/TO_USE.png"
          alt=""
        />
      </div>
      <div class="fixed_btn fixed_btn2">
        <img
          @click="step3Click()"
          src="../../../assets/market/btn3.png"
          alt=""
        />
      </div>
    </div>
        <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
        <div v-show="show" class="dialog_fixed" @click.prevent="show = false">
          <div  class="dialog_box">
            <div class="dialog">
              <div class="dia_title">Copy the link below and share with your friends! </div>
              <div class="dialog_content">
                <p style="font-size:3.5vw;">Hi! Richmond Night Market is opened! Wanna go? Free Richmond Night Market Coupon is here, https://m.richmondnightmarket.com , you can take a look first!</p>
              </div>
                <span class="copy-btn" ref="copy" @click.stop="Copy()">Copy</span>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import { coupon_list } from "@/api/en/night_market.js";
import { weshare }  from '@/api/zh/detail.js'
import Clipboard from 'clipboard'
import MHeaderRichmond from "@/components/en/m-header-richmond.vue";
export default {
  name: "Index",
  components: {
    MHeaderRichmond,
  },
  data() {
    return {
      nomoreVal: "loading...",
      searchVal: "",
      fixedShow: false,
      step2: true,
      step3: false,
      couponList:[],
      page: 1,
      loading: false,
      islock: false,
      show: false,
      localShow: false,
      images: [
        {
          src: require('@/assets/richmond/lingoAce.png'),
          href: 'https://ads.lingoace.com/ads/clue/base-page/#/init/home?channel=MA-BDG-BC-CM-WNM&marketingTeam=China&modeId=1&formKey=Chinese'
        },
        {
          src: require('@/assets/richmond/taking.png'),
          href: 'https://ei.saasxtool.com/'
        },
        {
          src: require('@/assets/richmond/super.png'),
          href: 'https://order.flashorder.ca/m/index.html'
        },
        {
          src: require('@/assets/richmond/style.png'),
          href: 'https://beautysharelife.com'
        },
      ]
    };
  },
  methods: {
    handleBack(){
      if (this.searchVal != '') {
        this.update()
      }else {
        if(this.$route.path != '/nightmarketcoupons') {
          this.$router.go(-1)
        } else {
          if (sessionStorage.getItem('fromPathNight')) {
            this.$router.push({path: sessionStorage.getItem('fromPathNight')})
          }else{
              this.$router.push('/nightmarketonline')
          }
        }
      }
    },
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.init();
        }
        this.islock = true
      }
    },
    handleHref(href) {
      window.location.href = href
    },
    update() {
      this.searchVal = ''
      this.search()
    },
    step2Click() {
        this.step2 = false;
        this.step3 = true;
    },
    step3Click() {
        let isFirstz = window.localStorage.getItem('isFirstz')
        if (!isFirstz) {
          window.localStorage.setItem('isFirstz', true);
        }
        this.step3 = false;
        this.fixedShow = false;
    },
    Copy() {
      this.clipboard.on('success', () => {
        this.$notify({ type: 'success', message: 'Copy the link successfully, share it with your friends!' })
        this.show = false;
      })
        this.clipboard.on('error', () =>{
        // 不支持复制
         this.show = false;
        this.$notify({ type: 'warning', message: 'Error, Browser does not support'})
      })
    },
    shareClick() {
      this.show = true;
    },
    toBuy() {
       window.location.href = 'https://richmondnightmarket.hellochat.com/'
    },
    useNow(id,state) {
      // if (this.searchVal != '') {
      //   this.$store.commit('set_night_offset', 0);
      // }else{
      //   this.$store.commit('set_night_offset', this.offsetTop);
      // }
      this.$router.push({path:"/richmond/coupon",query:{ b_id: id , state:  state}});
    },
    searchEnter() {
        this.$refs.activeElement.blur();
    },
    search() {
      let params = {}
        if (this.searchVal != "") {
            params.name = this.searchVal
            params.page = 1;
            params.status = 1;
          coupon_list(params).then(res => {  
              if(res){
                if (res.data.length == 0) {
                  this.nomoreVal = 'Opps! No Coupon for this booth yet… '
                }
                this.couponList = res.data;
              }
          })
          this.islock   = true
        }else{
          this.couponList = [];
          this.islock   = false
          this.page = 1;
          this.nomoreVal = 'loading...'
          this.init()
        }
    },
    init() {
       this.$store.commit('load')
        let params1 = { 
          // page: this.page,
          status : 0,
          level: 3
        }
        let params2 = { 
          // page: this.page,
          status : 0,
          level: 2
        }
        let params3 = { 
          // page: this.page,
          status : 0,
          level: 1
        }
        let params4 = {
          status : 1
        }
        this.islock = true
        this.loading  = true
        coupon_list(params1).then(res => {  
            this.loading  = false
            if(res){
                // if (this.page  <=  res.data.last_page) {
                //   this.islock = false
                  this.couponList = this.couponList.concat(res.data)
                  this.$store.commit('unload');
                // }else{
                //   this.islock   = true
                coupon_list(params2).then(res => {  
                      if(res){
                          this.couponList = this.couponList.concat(res.data)
                          // this.$store.commit('unload')
                          coupon_list(params3).then(res => {  
                              if(res){
                                  this.couponList = this.couponList.concat(res.data)
                                  coupon_list(params4).then(res => {  
                                      if(res){
                                          this.couponList = this.couponList.concat(res.data)
                                      }
                                  })
                              }
                          })
                      }
                }).finally(()=>{
                    this.$store.commit('unload');
                }) 
            }
        })
    },
    guide() {
      window.localStorage.removeItem('isFirstz')
     let isFirst = window.localStorage.getItem('isFirstz')
     if (this.localShow) {
       if (!isFirst) {
        this.fixedShow = true;
      }else{
        this.fixedShow = false;
      } 
     }
    },
    // 微信分享
    wxShare(){
      // let jsUrl = '' // 验证签名所需URL
      let shareUrl = window.location.href // 分享链接的URL
      // if (_isWechat() && _isIOS()) {
      //   // 微信浏览器&&苹果设备, 取出记录的第一次访问的URL
      //   jsUrl = this.$store.state.jsUrl
      // } else {
      //   jsUrl = window.location.href; // 当前页面URL
      // }
      let params = {
        url: window.location.href,
      }
      weshare(params).then(res => {
        if(res) {
           window.wx.config({
              debug: false, // 开启调试模式
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr:  res.data.nonceStr, // 必填，生成签名的随机串
              signature:  res.data.signature,// 必填，签名
              jsApiList: [
                // "onMenuShareTimeline",
                // "onMenuShareAppMessage",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ] 
          });

        
           window.wx.ready(function() {
             window.wx.checkJsApi({
                jsApiList: ["showMenuItems"],
                success: function () {
                      window.wx.showMenuItems({
                        menuList: [

                            'menuItem:share:appMessage', //发送给朋友

                            'menuItem:share:timeline' //分享到朋友圈

                        ]

                    });
                }

             });
             window.wx.updateAppMessageShareData({
              title: "Mooby & Nightmarket", // 分享标题
              link:shareUrl, // 分享链接
              imgUrl: 'https://m.moobyyoho.com/nightmarket.png', // 分享图标
              desc: 'Richmond Nightmarket Coupons Save Over 250Cad', // 分享描述
              success () {
                //  that.$notify({ type: 'success', message: 'Successful sharing' });
              },

            });
             window.wx.updateTimelineShareData({
              title: "Mooby & Nightmarket", // 分享标题
              link: shareUrl, // 分享链接
              imgUrl: 'https://m.moobyyoho.com/nightmarket.png', // 分享图标
              desc: 'Richmond Nightmarket Coupons Save Over 250Cad', // 分享描述
              success () {
                //  that.$notify({ type: 'success', message: 'Successful sharing' });
              },
            });
          })
        }
      })
    },
  },
  created() {
    this.init();
  },
  mounted() {
    // this.guide();
    this.wxShare();
  },
  updated(){
      let copy = this.$refs.copy;
      this.clipboard = new Clipboard(copy, {
        text: ()=> {
          return 'Hi! Richmond Night Market is opened! Wanna go? Free Richmond Night Market Coupon is here, https://m.richmondnightmarket.com , you can take a look first!'
        }
      })
},
beforeRouteEnter(to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    if (from.path=='/zh'||from.path=='/en') {
        sessionStorage.setItem('fromPathNight',from.path)
      }
      // vm=>{
    //   if (from.query.guied == 1) {
    //     vm.localShow = true;
    //   }
    // }
    next();
},
};
</script>

<style scoped lang="less">
.en-m-header {
  width: 100%;
  height: 44px;
  background-color: #EDEDED;
  padding: 0 12px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  &>img:nth-of-type(1){
    width: 5vw;
    height: 4.5vw;
    position: relative;
    z-index: 8;
  }
  &>span {
    position: relative;
    z-index: 8;
    min-width: 9.33333vw;
    &>img{
      width: 8vw;
      height: 8vw;
    }
    &>span,&>span>a{
      font-size:4.26667vw;
      font-weight:500;
      color: #333;
    }
  }
  &>p {
    font-size:4.26667vw;
    font-weight:400;
    color:#333;
    line-height:6.66667vw;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 11.73333vw;
    padding: 0 21.33333vw;
  }
}
.richmond {
  @media screen and (min-width: 1200px){
    .fixed_top_STEP3 {
      display: none !important;
    }
  }
  position: relative;
  /deep/ .van-popup {
    padding: 50px;
  }
  font-size: 16px;
  font-family: FuturaBT-BoldItalic;
  li {
    list-style: none;
  }
  width: 100%;
  height: 100%;
  // background-color: #030424;
  .dialog_fixed {
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.664);
    width: 100vw;
    height: 100%;
    z-index: 999;
    .dialog_box {
      background-color: #fff;
      width: 90%;
      margin: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 3vw;
    }
    .dialog {
      padding: 3vw;
      .dia_title {
        padding: 2vw 0;
        font-size: 4.5vw;
      }
      .dialog_content {
        border: solid 1px rgb(209, 209, 209);
        padding: 3vw 2vw;
        p {
          background: rgb(255, 184, 62);
          color: #fff;
        }
      }
      .copy-btn {
        display: block;
        font-size: 4.5vw;
        width: 50%;
        margin: 5vw auto 2vw;
        text-align: center;
        height: 10vw;
        line-height: 10vw;
        background: rgb(255, 184, 62);
        border-radius: 2vw;
        color: #fff;
        cursor: pointer;
      }
    }
    .van-hairline--top {
      display: none;
    }
  }
  .main {
    width: 100%;
    height: calc(100vh - 43px);
    position: relative;
    top: 43px;
     overflow-y: scroll;
    background: url("../../../assets/richmond/couponbg.png") no-repeat #249FF2;
    background-size: 100%;
    .update {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 28px;
      height: 28px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .main-head {
      padding: 60px 0 0;
      img {
        display: block;
        margin: auto;
        width: 296px;
        height: 88px;
      }
    }
    /deep/ .van-swipe {
      .van-swipe__indicators {
        left: 100%;
        transform: translateX(-130%);
      }
    }
    /deep/ .van-swipe {
      width: 95%;
      border-radius: 10px;
      margin: 20px auto;
      height: 96px;
      .van-swipe__track {
        width: 100%;
        height: 96px;
        border-radius: 10px;
        .van-swipe-item {
          width: 100%;
          border-radius: 10px;
          height: 96px;
          img {
            width: 100%;
            border-radius: 10px;
            margin: auto;
            display: block;
            height: auto;
          }
        }
      }
    }
    .searchVal {
      width: 95%;
      margin: 10px auto;
      height: 50px;
      z-index: 9;
      background: url("../../../assets/richmond/btnBg.png");
      background-size: 100% 100%;
      img {
        position: relative;
        width: 20px;
        height: 20px;
        top: 8px;
        left: 10%;
        display: inline-block;
        z-index: 9;
      }
      input {
        width: 75%;
        border-radius: 0 20px 20px 0;
        height: 40px;
        position: relative;
        outline: none;
        top: 3px;
        font-size: 15px;
        background-color: transparent;
        border: none;
        color: #000;
        left: 12%;
        z-index: 9;
      }
    }
    .coupon {
      z-index: 1;
      position: relative;
      min-height: calc(100vh - 320px);
      width: 95%;
      margin: 0 auto 50px;
      padding: 10px 2.5% 100px;
      box-sizing: border-box;
      background: rgb(255, 220, 56);
      border-radius: 15px;
      // background-image: url("../../../assets/richmond/bg11.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      margin-bottom: 50px;
      .nomore {
          text-align: center;
          font-size: 30px;
          color: #666;
          position: relative;
          top: 200px;
      }
      .item {
        width: 97%;
        height: 198px;
        padding-top: 24px;
        box-sizing: border-box;
        margin: 10px 5px;
        background-image: url("../../../assets/richmond/background1.png");
        background-size: 100% 100%;
        position: relative;
        .hot {
          width: 62px;
          height: 62px;
          top: 0px;
          left: 8px;
          display: block;
          position: absolute;
          z-index: 99;
        }
        .item-pic {
          width: 290px;
          height: 113.5px;
          margin: auto;
          img {
            width: 290px;
            height: 113.5px;
            border-radius: 10px;
          }
          .item-pic-grey {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
            filter: gray;
          }
        }
        .item-content {
          width: 90%;
          margin: 12px auto 0;
          display: flex;
          justify-content: space-between;
          .item-market {
            display: flex;
            div {
              line-height: 33px;
              font-weight: 600;
              margin-right: 5px;
              img {
                width: 24px;
                margin-top: 5px;
              }
            }
            .addressMap {
              width: 30vw;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .use-btn {
            height: 33px;
            text-align: center;
            box-sizing: border-box;
            background: orangered;
            border-radius: 18px;
            color: #fff;
            line-height: 33px;
            font-size: 16px;
            width: 139px;
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
            }
          }
          .use-btn2 {
            width: 139px;
            text-align: center;
            height: 33px;
            box-sizing: border-box;
            background: #979797;
            border-radius: 18px;
            color: #fff;
            line-height: 33px;
            font-size: 16px;
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
            }
          }
          .use-btn3 {
            width: 139px;
            text-align: center;
            height: 33px;
            box-sizing: border-box;
            background: #FFDA34;
            border-radius: 18px;
            color: #000000;
            line-height: 33px;
            font-size: 16px;
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .item2 {
        width: 97%;
        height: 198px;
        padding-top: 24px;
        box-sizing: border-box;
        margin: 10px 5px;
        background-image: url("../../../assets/richmond/background2.png");
        background-size: 100% 100%;
        position: relative;
        .hot {
          width: 62px;
          height: 62px;
          top: -1px;
          left: 7px;
          display: block;
          position: absolute;
          z-index: 99;
        }
        .item-pic {
          width: 290px;
          height: 113.5px;
          margin: auto;
          img {
            width: 290px;
            height: 113.5px;
            border-radius: 10px;
          }
          .item-pic-grey {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
            filter: gray;
          }
        }
        .item-content {
          width: 90%;
          margin: 12px auto 0;
          display: flex;
          justify-content: space-between;
          .item-market {
            display: flex;
            div {
              line-height: 33px;
              font-weight: 600;
              margin-right: 5px;
              img {
                width: 24px;
                margin-top: 5px;
              }
            }
            .addressMap {
              width: 30vw;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .use-btn {
            height: 33px;
            text-align: center;
            box-sizing: border-box;
            background: orangered;
            border-radius: 18px;
            color: #fff;
            line-height: 33px;
            font-size: 16px;
            width: 139px;
            img {
              display: inline-block;
              height: 16px;
            }
          }
          .use-btn2 {
            width: 139px;
            text-align: center;
            height: 33px;
            box-sizing: border-box;
            background: #979797;
            border-radius: 18px;
            color: #fff;
            line-height: 33px;
            font-size: 16px;
            img {
              display: inline-block;
              height: 16px;
            }
          }
          .use-btn3 {
            width: 139px;
            text-align: center;
            height: 33px;
            box-sizing: border-box;
            background: #FFDA34;
            border-radius: 18px;
            color: #000000;
            line-height: 33px;
            font-size: 16px;
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .flex-btn {
    z-index: 9;
    bottom: 0;
    height: 60px;
    background-color: #030424ee;
    position: fixed;
    display: flex;
    padding: 10px 10px;
    justify-content: space-around;
    border-radius: 25px 25px 0 0;
    width: 100%;
    .flex-item {
      width: 300px;
      height: 40px;
      background: #089bd6;
      border: 1px solid rgba(51, 165, 255, 0);
      border-radius: 30px;
      div {
        position: relative;
        top: -2px;
        width: 100%;
        height: 38px;
        text-align: center;
        color: #fff;
        line-height: 38px;
        background: linear-gradient(
          180deg, rgba(51, 165, 255, 0) 20.83%, 
          rgba(77, 176, 255, 0.9) 100%), 
          #008EFF;
        border-radius: 30px;
      }
    }
  }
  .fixed {
    .fixed_search {
      width: 45%;
      margin: auto;
      position: fixed;
      bottom: 10px;
      left: 2.5%;
      img {
        position: relative;
        display: block;
        width: 100%;
        z-index: 9;
      }
    }
    .fixed_search2 {
      width: 45%;
      margin: auto;
      position: fixed;
      bottom: 1%;
      right: 2.5%;
      img {
        position: relative;
        display: block;
        width: 100%;
        z-index: 9;
      }
    }
    .fixed_search3 {
      width: 150px;
      height: 40px;
      margin: auto;
      position: absolute;
      top: 487px;
      right: 40px;
      img {
        position: relative;
        display: block;
        width: 100%;
        z-index: 9;
      }
    }
    .fixed_top {
      width: 200px;
      left: 70px;
      z-index: 8;
      position: absolute;
      top: 330px;
      height: 108px;
      background-image: url("../../../assets/market/Group946.png");
      background-size: 100% 100%;
    }
    .fixed_left {
      width: 250px;
      left: 50px;
      z-index: 8;
      position: absolute;
      bottom: 70px;
      height: 108px;
      background-image: url("../../../assets/market/Group938.png");
      background-size: 100% 100%;
    }
    .fixed_right {
      width: 250px;
      left: 50px;
      z-index: 8;
      position: fixed;
      bottom: 70px;
      height: 108px;
      background-image: url("../../../assets/market/Group939.png");
      background-size: 100% 100%;
    }
    .fixed_btn2 {
      top: 20%;
    }
  }
}
@media screen and (min-width: 1200px) {
  .coupon {
    min-height: 150vh !important;
    .nomore {
      top: 60vh !important;
    }
  }
}
</style>